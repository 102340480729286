<template>
  <div class="sing-up">
    <div class="sing-up__texts">
      <div class="sing-up__main-title">SIGN UP</div>
      <div class="sing-up__welcome">Please introduce yourself and let us know how to contact you.</div>
    </div>
    <div class="sing-up__container">
      <VOverlay v-if="$store.state.auth.statusSignup === requestStatus.PENDING" dark>
        <VLoader dark />
      </VOverlay>
      <form @submit.prevent="submitForm">
        <VTextFieldAuth
          label="First Name *"
          :error="$v.form.firstName.$error"
          v-model="form.firstName"
          class="sing-up__mb"
        />
        <VTextFieldAuth
          label="Last Name *"
          :error="$v.form.lastName.$error"
          v-model="form.lastName"
          class="sing-up__mb"
        />
        <VTextFieldAuth
          label="Email *"
          type="email"
          :error="$v.form.email.$error"
          v-model="form.email"
          class="sing-up__mb"
        />
        <VTextFieldAuth
          label="Email (confirm) *"
          type="email"
          :error="$v.form.email_confirmation.$error"
          v-model="form.email_confirmation"
          class="sing-up__mb"
        />
        <VTextFieldAuth
          label="New password"
          type="password"
          :error="$v.form.password.$error"
          v-model="form.password"
          class="sing-up__mb"
        />
        <VTextFieldAuth
          label="Confirm password"
          type="password"
          :error="$v.form.password_confirmation.$error"
          v-model="form.password_confirmation"
          class="sing-up__mb"
        />
        <VSelectFieldAuth
          label="Instant Messaging"
          :options="messengers"
          v-model="form.messengerId"
          class="sing-up__mb"
        />
        <VTextFieldAuth
          v-if="form.messengerId !== '0'"
          label="Instant Messaging Identifier"
          v-model="form.socialTextValue"
          class="sing-up__mb"
        />
        <button class="sing-up__form-btn" type="submit">Submit application</button>
      </form>
    </div>
    <PopupSignup v-if="$store.state.modalTableColumns.toggleSignupSuccess" />
  </div>
</template>

<script>
import { required, sameAs, email, minLength } from 'vuelidate/lib/validators';
import VSelectFieldAuth from '@/js/components/common/form/VSelectFieldAuth';
import VTextFieldAuth from '@/js/components/common/form/VTextFieldAuth';
import PopupSignup from "@/js/pages/Auth/singup/PopupSignup";
import { requestStatus } from '@/js/utils/constants';

export default {
  name: "SignUp",
  metaInfo: {
    title: 'Registration'
  },
  components: {
    VSelectFieldAuth,
    VTextFieldAuth,
    PopupSignup,
  },
  data() {
    return {
      requestStatus,
      togglePassword: false,
      showPopupSignup: false,
      loader: false,
      formDefault: {
        firstName: '',
        lastName: '',
        email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
        messengerId: '',
        socialTextValue: ''
      },
      form: {
        firstName: '',
        lastName: '',
        email: '',
        email_confirmation: '',
        password: '',
        password_confirmation: '',
        messengerId: '0',
        socialTextValue: ''
      },
      messengers: [
        {
          id: 0,
          label: 'None'
        },
        {
          id: 1,
          label: 'Skype'
        },
        {
          id: 3,
          label: 'Telegram'
        },
        {
          id: 2,
          label: 'Yahoo Messenger'
        },
      ]
    };
  },
  validations: {
    form: {
      firstName: {
        required,
        minLength: minLength(1)
      },
      lastName: {
        required,
        minLength: minLength(1)
      },
      email: {
        required,
        email
      },
      email_confirmation: {
        required,
        sameAsPassword: sameAs('email')
      },
      password: { required },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
    }
  },
  methods: {
    filterBySelect(option, slug) {
      this.form[slug] = option.id;
    },
    clearFilter(slug) {
      this.form[slug] = '';
    },
    resetForm() {
      this.form = { ...this.form, ...this.formDefault };
    },
    reload: function () {
      this.$router.go(this.$router.currentRoute);
    },
    async submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      const formData = {};
      formData.first_name = this.form.firstName;
      formData.last_name = this.form.lastName;
      formData.email = this.form.email;
      formData.email_confirmation = this.form.email_confirmation;
      formData.password = this.form.password;
      formData.password_confirmation = this.form.password_confirmation;
      formData.instant_messaging_id = this.form.messengerId;
      formData.instant_messaging_identifier = this.form.socialTextValue;

      try {
        const res = await this.$store.dispatch('auth/signup', formData);
        if (res.data.result) {
          this.$store.commit('modalTableColumns/TOGGLE_SIGNUP_SUCCESS', true);
        } else {
          alert(res.data.error);
          this.resetForm();
          this.reload();
        }
      } catch (e) {
        console.log('err=>', e);
        alert(e.response.data);
      }
      this.resetForm();
    },
  }
};
</script>

<style lang="scss" scoped>
.sing-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 100vh;
  font-family: var(--Montserrat);
  padding: 80px 24px 24px;
  background: center/cover no-repeat url(~@/assets/img/auth/bg-signup.jpg) #ffffff;

  @media (max-width: 900px) {
    padding-top: 126px;
  }
  @media (max-width: 480px) {
    padding-top: 150px;
  }

  &__texts {
    width: 100%;
    max-width: 680px;
    text-align: center;
  }

  &__main-title {
    color: #ffffff;
    font-size: 42px;
    font-family: var(--DrukWide);
    line-height: 1.55;
    font-weight: bold;
    text-transform: uppercase;
    padding: 30px 0 20px;
  }

  &__welcome {
    color: #ffffff;
    font-size: 20px;
    line-height: 1.55;
    font-weight: 700;
    margin-bottom: 20px;
  }

  &__container {
    position: relative;
    width: 100%;
    max-width: 510px;
    padding: 40px 35px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    overflow: hidden;
  }

  &__form-btn {
    color: #ffffff;
    background-color: #6980ff;
    border-radius: 8px;
    font-family: var(--Montserrat);
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    height: 45px;
    padding: 0 15px;
    display: block;
    border: none;
    cursor: pointer;
  }

  &__mb {
    margin-bottom: 20px;
  }
}
</style>