<template>
  <div class="popup-signup">
    <div class="popup-signup__container">
      <div class="popup-signup__title mt-2 mb-5">
        <v-icon icon="logo" class="popup-signup__logo mb-2"/>
        <div>Adstellar</div>
      </div>
      <div class="popup-signup__subtitle mb-3">Thank you<br/> for submitting your details!</div>
      <div class="mb-2">We have sent an email to your address with confirmation of receiving your application.</div>
      <div class="mb-5">In the meantime, you can learn more about Adstellar on our site.</div>
      <a href="https://adstellar.io/" class="popup-signup__btn" @click="backToSite">Back to site</a>
    </div>
  </div>
</template>

<script>

export default {
  name: "PopupSignup",
  components: {},
  methods: {
    backToSite() {
      this.$store.commit('modalTableColumns/TOGGLE_SIGNUP_SUCCESS', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-signup {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: absolute;
  z-index: 10;
  background-color: rgb(0, 0, 0, 0.5);
  top: 0;
  left: 0;


  &__container {
    max-width: 600px;
    width: 100%;
    padding: 80px 60px;
    background: var(--white-main);
    box-shadow: 0px 3px 10px 3px rgba(43, 72, 105, 0.11);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: var(--white-main);
    text-align: center;
    font-size: 18px;
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: var(--grey-dark);
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.5em;
    margin-bottom: 48px;
  }

  &__subtitle {
    color: var(--blue-main);
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    text-align: center;
  }

  &__logo {

    width: 120px;
    height: 80px;
    margin-bottom: 20px;
  }

  &__btn {
    max-width: 173px;
    width: 100%;
    height: 48px;
    background: var(--orange-main);
    border-radius: 8px;
    color: var(--white-main);
    border: 1px solid var(--orange-main);
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

}

</style>