<template>
  <label class="v-text-field-auth">
    <span class="v-text-field-auth__label">{{ label }}</span>
    <input
      :type="type"
      class="v-text-field-auth__input"
      :class="{
        'v-text-field-auth__input--error': error,
      }"
      :value="value"
      @input="$emit('input', $event.target.value)"
    >
  </label>
</template>

<script>
export default {
  name: "VTextFieldAuth",
  props: {
    type: {
      type: String,
      default: 'text',
    },
    label: String,
    value: String,
    error: {
      type: Boolean,
      default: false
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field-auth {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__label {
    display: block;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 5px;
  }

  &__input {
    color: #ffffff;
    border: none;
    border-bottom: 1px solid #ffffff;
    background-color: transparent;
    font-family: var(--DrukWide);
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    height: 23px;

    &:focus {
      outline: none;
    }

    &--full-width {
      max-width: 100%;
    }

    &--icon {
      padding: 0 48px 0 15px;
    }

    &--error {
      border: 1px solid var(--red-main);
    }
  }
}
</style>