<template>
  <div class="v-select-auth mb-2">
    <label class="v-select-auth__label">Instant Messaging</label>
    <select
      class="v-select-auth__field"
      :value="value"
      @change="$emit('input', $event.target.value)"
    >
      <option
        v-for="msg in options"
        :key="msg.id"
        :value="msg.id"
      >
        {{ msg.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "VSelectFieldAuth",
  props: {
    label: String,
    options: Array,
    value: String,
  },
};
</script>

<style lang="scss" scoped>
.v-select-auth {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__label {
    display: block;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 5px;
  }

  &__field {
    color: #ffffff;
    border: none;
    border-bottom: 1px solid #ffffff;
    background-color: transparent;
    font-family: var(--DrukWide);
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    height: 23px;

    &:focus {
      outline: none;
    }

    option {
      color: #000000;
    }
  }
}
</style>